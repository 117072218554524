@font-face {
  font-family: "Butler Black";
  src: local("Butler Black"),
    url(./assets/fonts/BUTLER_BLACK.OTF) format("truetype");
}

@font-face {
  font-family: "Butler Bold";
  src: local("Butler Bold"),
    url(./assets/fonts/BUTLER_BOLD_0.OTF) format("truetype");
}

@font-face {
  font-family: "Butler ExtraBold";
  src: local("Butler ExtraBold"),
    url(./assets/fonts/BUTLER_EXTRABOLD_0.OTF) format("truetype");
}

@font-face {
  font-family: "Butler light";
  src: local("Butler light"),
    url(./assets/fonts/BUTLER_LIGHT_0.OTF) format("truetype");
}

@font-face {
  font-family: "Butler medium";
  src: local("Butler medium"),
    url(./assets/fonts/BUTLER_MEDIUM_0.OTF) format("truetype");
}

body {
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
}

@keyframes float {
  0% {
    transform: translatey(0px);
    transform: translate3d(0px, 0px, 0px);
  }
  50% {
    transform: translatey(-20px);
    transform: translate3d(-40px, -40px, -40px);
  }
  100% {
    transform: translatey(0px);
    transform: translate3d(0px, 0px, 0px);
  }
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}
